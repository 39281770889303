
import { useEffect, useState } from "react";
import HamMenu from "../HamMenu/HamMenu";
import Logo from "../Logo/Logo";
import Navigation from "../Navigation/Navigation";
import styles from './Header.module.css';
import { Button } from "@mui/joy";

const Header = (props) => {
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        window.innerWidth > 900 ? setIsMobile(false) : setIsMobile(true);
        window.addEventListener('resize', (e) => {
            window.innerWidth > 900 ? setIsMobile(false) : setIsMobile(true);
        })
    } , [isMobile])
    return (
        <header className={styles.Header}>
            <HamMenu />
            {isMobile ? null :<>
                <a rel="noreferrer" target="_blank" href="https://evand.com/events/flatearth-carbon">
                <Button variant="solid">
                ثبت نام در رویداد
                </Button>
                </a>
                <Navigation />
            </>}
            <Logo />
        </header>
    )
}

export default Header;