import { NavLink } from "react-router-dom";
import styles from './NavigationItem.module.css';

const NavigationItem = ({ children, to, link, blank }) => {
    return (
        link ? <a
            className={styles.NavigationItem}
            href={to}
            target={blank ? '_blank' : '_self'}
            rel="noreferrer">{children}</a> : <NavLink
                target={blank ? '_blank' : '_self'}
                activeClassName={styles.ActiveNavigationItem}
                className={styles.NavigationItem} to={to}>{children}</NavLink>

    )
}

export default NavigationItem;