import TeamMemeber from './TeamMember/TeamMemeber';
import styles from './TeamMemebers.module.css';

const TeamMemebers = ({ members , title}) => {
    const membersElement = Array.isArray(members) ? members.map((member , index) => {
        return (
            <TeamMemeber
                index ={index}
                image={member.image}
                name={member.name}
                position={member.position}
                key={member.name}
                instagram={member.instagram}
                linkedin={member.linkedin}
                twitter={member.twitter}/>
        )
    } 
    ): new Array(16).fill('').map(()=><TeamMemeber loading /> ) 
    return (
        <div className={styles.TeamMemebers}>
            <h2 className={styles.Title}>{title}</h2>
            <div className={styles.TeamMemebersContainer}>
            {membersElement}
            </div>
        </div>
    )
}

export default TeamMemebers;