import React from "react";
import BlurCircle from "../../Components/BlurCircle/BlurCircle";
import Head from "../../Components/Head/Head";
import styles from "./Contact.module.css";
const Contact = (props) => {
  return (
    <div className={styles.About}>
      <Head title="رویداد زمین صاف - تماس باما " description="راه‌های ارتباطی با رویداد فلت ارث" />
      <section className={[styles.Section, styles.Top].join(' ')}>
        <div className={styles.Content}>
          <h1>تماس با ما</h1>
          <p>از این راه‌ها می‌توانید با ما ارتباط برقرار کنید.</p>
          <h2 className={styles.ContactWayTitle}>اطلاعات تماس</h2>
          <div className={styles.ContactWay}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 384 384" xmlSpace="preserve"><g><g><path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594    c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448    c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813    C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z"></path></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
            </svg>
            <div style={{direction : 'ltr'}}>0902-3000-221</div>
          </div>
          <div className={styles.ContactWay}>
            <svg xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve"><path d="M371.496,485.52C327.36,505.384,289.104,512,236.872,512C114.76,512,7.352,424.456,7.352,280.28  C7.352,130.208,116.224,0,282.488,0c129.472,0,222.16,89.016,222.16,212.6c0,107.4-60.32,175.08-139.768,175.08  c-34.576,0-59.584-17.656-63.264-56.648h-1.472c-22.8,37.52-55.904,56.648-94.896,56.648c-47.816,0-82.392-35.312-82.392-95.64  c0-89.744,66.208-171.4,172.136-171.4c32.368,0,69.144,8.096,87.544,18.392l-22.064,136.096  c-7.352,43.392-2.208,63.264,18.392,63.992c31.632,0.736,71.352-39.728,71.352-124.32c0-95.632-61.792-169.936-175.824-169.936  c-112.544,0-211.128,88.28-211.128,228.784c0,122.848,78.712,192.736,188.328,192.736c37.52,0,77.248-8.088,106.664-23.544  L371.496,485.52z M306.024,181.704c-5.888-1.472-13.248-2.944-22.808-2.944c-48.544,0-86.808,47.816-86.808,104.456  c0,27.952,12.512,45.608,36.784,45.608c27.216,0,55.912-34.576,62.528-77.24L306.024,181.704z"></path><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
            </svg>
            <div>info@flatearthevent.ir</div>
          </div>
          <div className={styles.ContactWay}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
              <path d="M0 0h24v24H0z" fill="none" /><path d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z" />
            </svg>
            <div>میدان هروی - بلوار پناهی‌نیا - خیابان توحیدی - پلاک۷۴ - طبقه دوم</div>
          </div>
          <h2 className={styles.ContactWayTitle}>شبکه‌های اجتماعی</h2>
          <div className={styles.SocialMedia}>
            <a href="https://www.instagram.com/flatearthevent/" rel="noreferrer" target="_blank" className={styles.ContactWay}>

              <svg viewBox="0.008 -0.053 499.971 500.036" >
                <path d="M 138.89 -0.053 C 62.31 -0.053 0.008 62.26 0.008 138.847 L 0.008 361.083 C 0.008 437.672 62.31 499.983 138.89 499.983 L 361.099 499.983 C 437.677 499.983 499.979 437.672 499.979 361.083 L 499.979 138.847 C 499.979 62.26 437.677 -0.053 361.099 -0.053 L 138.89 -0.053 Z M 138.89 55.505 L 361.099 55.505 C 407.046 55.505 444.43 92.897 444.43 138.847 L 444.43 361.083 C 444.43 407.033 407.046 444.425 361.099 444.425 L 138.89 444.425 C 92.941 444.425 55.554 407.033 55.554 361.083 L 55.554 138.847 C 55.554 92.897 92.941 55.505 138.89 55.505 Z M 388.875 83.285 C 373.522 83.285 361.099 95.733 361.099 111.07 C 361.099 126.399 373.522 138.847 388.875 138.847 C 404.213 138.847 416.657 126.399 416.657 111.07 C 416.657 95.733 404.213 83.285 388.875 83.285 Z M 249.994 111.07 C 173.419 111.07 111.113 173.375 111.113 249.969 C 111.113 326.555 173.419 388.863 249.994 388.863 C 326.57 388.863 388.875 326.555 388.875 249.969 C 388.875 173.375 326.57 111.07 249.994 111.07 Z M 249.994 166.627 C 295.938 166.627 333.326 204.019 333.326 249.969 C 333.326 295.914 295.938 333.306 249.994 333.306 C 204.049 333.306 166.661 295.914 166.661 249.969 C 166.661 204.019 204.049 166.627 249.994 166.627 Z" ></path>
              </svg>
            </a>
            <a href="#" rel="noreferrer" target="_blank" className={styles.ContactWay}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="m21.552 7.749c0-.217-.008-.428-.018-.636.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.752.206-1.678.198-2.221.078.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 9.054 0 14.004-7.5 14.004-14.001z" fill="white"></path>
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/flat-earth" rel="noreferrer" target="_blank" className={styles.ContactWay}>
              <svg xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                <g fill="white"><path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"></path><path d="m.396 7.977h4.976v16.023h-4.976z"></path><path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"></path></g>
              </svg>
            </a>
          </div>
        </div>
      </section>
      {/* <BlurCircle size='240px' top='50vh' right='32px' color='white' opacity='0.75' /> */}
      <BlurCircle size='240px' top='25vh' left='32px' color='white' opacity='0.25' />
     
    </div>
  )
}

export default Contact;