import { convertToPersianNumber } from '../../Utils/DigitConvertor';
import styles from './CountdownRenderer.module.css';

const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    const prettier = (number) => {
        return  convertToPersianNumber(`${number}`)
    }
    return (
        <div className={styles.CountdownRenderer}>
            <span >{prettier(days)}<span className={styles.Highlight}> روز </span> و </span>
            <span>{prettier(hours)}
            <span className={styles.Highlight}> ساعت </span> و  {prettier(minutes)} 
            <span className={styles.Highlight}> دقیقه </span> و {prettier(seconds)} 
            <span className={styles.Highlight}> ثانیه</span></span>
        </div>
    )
}

export default CountdownRenderer;