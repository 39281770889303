import React, { Suspense, useEffect, useRef, useState, Component } from "react";
import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import { ARButton } from "three/examples/jsm/webxr/ARButton";
class Earth extends Component {
    constructor(){
        super();
        this.renderArea = React.createRef();
        this.canvas = React.createRef()
    }
    componentDidMount() {
        const tagsim = 1.15;
        let width = window.innerWidth / tagsim;
        let height = window.innerHeight / tagsim;
        const InitialWidth = window.innerWidth;
        const InitialHeight = window.innerHeight;
        // const InitialAspectRatio = InitialWidth / InitialHeight ;
        if (width > height) {
            width = height
        }
        else {
            height = width;
        }
        var camera = new THREE.PerspectiveCamera(
            50,
            width / height,
            1,
            1000
          );
        const ARCamera = new THREE.PerspectiveCamera(
          50,
          InitialWidth / InitialHeight,
            1,
            1000,
          )
        window.addEventListener('resize' , ()=>{
            width = (window.innerWidth / tagsim);
            height = (window.innerHeight / tagsim);
            if (width > height) {
                width = height
            }
            else {
                height = width;
            }
        renderer.setSize(width ,height);
        camera.aspect = width / height
    
    })
  
        var scene = new THREE.Scene();
        camera.position.set(5, 7, 10);
        ARCamera.position.set(5, 7, 15)
        var renderer = new THREE.WebGLRenderer({canvas : this.canvas , antialias : true , alpha : true});
        renderer.setSize(width, height);
        renderer.xr.enabled = true;
        this.renderArea.appendChild(renderer.domElement);
        // this.renderArea.appendChild(ARButton.createButton(
        //   renderer,
        //   { requiredFeatures: ["hit-test"] },
        // ));
    
        // const light = new THREE.PointLight(0x404040, 10);
        // light.position.set(100, 100, 100);
        // scene.add(light);
        const light2 = new THREE.AmbientLight(0x404040, 4.5); // soft white light
        scene.add(light2);
        var controls = new OrbitControls(camera, renderer.domElement );
        controls.enableZoom = false;
        controls.enablePan = false
        // controls.enableRotate = false;
        controls.autoRotate = true;
        var boxGeom = new THREE.BoxBufferGeometry(5, 5, 5, 100, 100, 100);
    
        // this is the shortened part from the official example to create the sphere morph targets
        var pos = boxGeom.attributes.position;
        boxGeom.morphAttributes.position = [];
        var spherePositions = [];
        var v3 = new THREE.Vector3();
        for (var i = 0; i < pos.count; i++) {
          v3.fromBufferAttribute(pos, i).setLength((2.5 * Math.sqrt(6) + 2.5) * 0.5);
          spherePositions.push(v3.x, v3.y, v3.z);
        }
        boxGeom.morphAttributes.position[0] = new THREE.Float32BufferAttribute(
          spherePositions,
          3
        );
        const loader = new THREE.TextureLoader();
        const cubeMaterials = [
          new THREE.MeshStandardMaterial({
            map: loader.load("EarthTextures/1.png"),
            bumpMap: loader.load("EarthTextures/1.1.png"),
            specularMap: loader.load("EarthTextures/1.2.png"),
            specular: new THREE.Color("grey"),
            bumpScale: 0.005,
          }),
          //right side
          new THREE.MeshStandardMaterial({
            map: loader.load("EarthTextures/2.png"),
            bumpMap: loader.load("EarthTextures/2.1.png"),
            specularMap: loader.load("EarthTextures/2.2.png"),
            specular: new THREE.Color("grey"),
            bumpScale: 0.005,
          }), //left side
          new THREE.MeshStandardMaterial({
            map: loader.load("EarthTextures/3.png"),
            bumpMap: loader.load("EarthTextures/3.1.png"),
            specularMap: loader.load("EarthTextures/3.2.png"),
            specular: new THREE.Color("grey"),
            bumpScale: 0.005,
          }), //top side
          new THREE.MeshStandardMaterial({
            map: loader.load("EarthTextures/4.png"),
            bumpMap: loader.load("EarthTextures/4.1.png"),
            specularMap: loader.load("EarthTextures/4.2.png"),
            specular: new THREE.Color("grey"),
            bumpScale: 0.005,
          }), //bottom side
          new THREE.MeshStandardMaterial({
            map: loader.load("EarthTextures/5.png"),
            bumpMap: loader.load("EarthTextures/5.1.png"),
            specularMap: loader.load("EarthTextures/5.2.png"),
            specular: new THREE.Color("grey"),
            bumpScale: 0.005,
          }), //front side
          new THREE.MeshStandardMaterial({
            map: loader.load("EarthTextures/6.png"),
            specularMap: loader.load("EarthTextures/6.2.png"),
            specular: new THREE.Color("grey"),
            bumpMap: loader.load("EarthTextures/6.1.png"),
            bumpScale: 0.005,
          }), //back side
        ];
    
        var box = new THREE.Mesh(boxGeom, cubeMaterials);
        box.rotation.set(0 , 45 ,0)
        scene.add(box);
        box.scale.set(1.5, 0.02, 1.5);
        // user's custom properties and methods
        box.userData.isHovering = false;
        box.userData.currentAction = null;
        box.userData.toSphere = () => {
          action(1);
        };
        box.userData.toBox = () => {
          action(0);
        };
    
        // tweening function
        function action(influence) {
          if (box.userData.currentAction) box.userData.currentAction.stop();
          if (influence === 0) {
            // box.scale.set(1.5 , 0.25 , 1.5)
            new TWEEN.Tween(box.scale)
              .to(
                {
                  x: 1.4,
                  y: 0.02,
                  z: 1.4,
                },
                125
              )
              .start()
              .onComplete(() => {
                new TWEEN.Tween(box.scale)
                  .to(
                    {
                      x: 1.5,
                      y: 0.02,
                      z: 1.5,
                    },
                    125
                  )
                  .start();
              });
          } else {
            // box.scale.set(1 , 1 , 1)
            new TWEEN.Tween(box.scale)
              .to(
                {
                  x: 1.25,
                  y: 1.25,
                  z: 1.25,
                },
                125
              )
              .start()
              .onComplete(() => {
                new TWEEN.Tween(box.scale)
                  .to(
                    {
                      x: 1,
                      y: 1,
                      z: 1,
                    },
                    125
                  )
                  .start();
              });
          }
          box.userData.currentAction = new TWEEN.Tween(box.morphTargetInfluences)
            .to(
              {
                0: influence,
              },
              125
            )
            .start();
        }
        let flat = true;
       this.renderArea.addEventListener('click' , ()=>{
        if (flat) {
            box.userData.toSphere()
            flat = false;
            this.props.onClick();
        }
        else {
            box.userData.toBox();
            flat = true
        }
       })
    
        renderer.setAnimationLoop(() => {
          TWEEN.update();
          box.rotation.y += 0.002
          if (renderer.xr.isPresenting) {
            renderer.render(scene, ARCamera);    
            // renderer.setSize(InitialWidth , InitialHeight)
            // controls.dispose()
          }
          else {
            renderer.render(scene, camera);
          }
          
        });
    }
 
  render() {
    
    return <div style={{position : 'relative',}} ref={(el)=> {this.renderArea = el}}>
        {/* <h1 style={{fontFamily :'IranSansDN' ,
         position : 'absolute' , right : '0',
        top : '0'}}>بزن روش</h1> */}
        <canvas  width='100%' height="100%"  ref={(el)=> {this.canvas = el}}/>
    </div>;
  }
}

export default Earth;
