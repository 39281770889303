import { useEffect, useState } from "react";
import styles from "./GoUpButton.module.css";

const GoUpButton = () => {
  const [show, setShow] = useState(false);
  useEffect(()=>{
    window.addEventListener('scroll' , (e)=>{
      window.scrollY > window.innerHeight / 2 ? setShow(true) : setShow(false);
    })
  })
  return (<button className={[styles.GoUpButton , show ? styles.Show : null].join(' ')} onClick={()=>{
    window.scrollTo({top : 0, left:0 , behavior : 'smooth'})
  }}>
    <div className={styles.Arrows}>
    <div className={styles.LeftArrow}></div>
    <div className={styles.RightArrow}></div>
    </div>
  </button>);
};

export default GoUpButton;
