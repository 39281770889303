import Countdown from "react-countdown";
import LinkButton from "../../Components/Buttons/LinkButton/LinkButton";
import CountdownRenderer from "../../Components/CountdownRenderer/CountdownRenderer";
import styles from "./Home.module.css";
import Earth from "../../Components/Earth/Earth";

import Head from "../../Components/Head/Head";
import { Button, ModalClose, Typography } from "@mui/joy";
import { useState } from "react";
import { Link } from "react-router-dom";
// import satellite1 from './img/satellite1.png'
const StarsArray = new Array(50).fill(1);
const Home = (props) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className={styles.Home}>
      <Head
        title="رویداد زمین صاف - خانه "
        description="تغییر دهنده زمین صاف باش!"
      />
      <section className={[styles.Section, styles.Intro].join(" ")}>
        <div className={styles.Content}>
          <div className={styles.Details}>
            <h1>می‌دانید هنوز افرادی هستند که فکر می‌کنند زمین صاف است؟</h1>
            <h2>
              ما فکر نمی‌کنیم که زمین گرد نیست، اما هنوز زمین‌های صاف وجود دارند
              که باید عوض شوند.
            </h2>
            <Countdown
              date={new Date(2024, 10, 8, 10, 30)}
              renderer={(props) => <CountdownRenderer {...props} />}
            />
            <a rel="noreferrer" target="_blank" href="https://evand.com/events/flatearth-carbon">
              <Button variant="solid">
                ثبت نام در رویداد
              </Button>

            </a>
            <a style={{ marginRight: '8px' }} rel="noreferrer" target="_blank" href="https://c.flatearthevent.ir">
              <Button variant="soft">
                تست
                " کدوم عنصری؟"
              </Button>

            </a>
          </div>
          <div className={styles.Earth}>
            <div className={styles.Help}>
              <div className={styles.Text}>بزن روی زمین ببین چی میشه!</div>
              <svg
                width="122"
                height="132"
                viewBox="0 0 122 132"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M111.531 28.313C106.625 22.8111 100.545 18.8413 93.1306 17.7316C81.2207 15.949 70.6723 23.0821 64.1007 32.6325C57.3138 42.4959 54.6663 58.103 58.0562 69.6463C60.0263 76.3547 64.1724 82.7396 69.4485 87.3242C71.8699 89.4281 75.5806 91.8257 78.9563 90.5502C85.9076 87.9236 80.5193 80.6713 77.6971 77.2655C70.8305 68.979 61.9186 60.388 51.4051 57.145C41.3098 54.0311 31.4884 59.3521 25.0739 67.0422C16.0792 77.8255 14.6669 91.3604 13.8312 104.73"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
                <path
                  d="M2.07053 100.459C4.05562 104.503 6.07545 108.539 7.99077 112.616C9.10648 114.991 9.58843 118.379 11.0187 120.525C11.3778 121.063 16.4129 114.084 16.713 113.746C20.7346 109.211 25.1568 105.126 30.1352 101.679"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div className={styles.Earth}>
              <Earth
                onClick={() => {
                  // setOpenModal(true);
                }}
              />
            </div>
          </div>
          {/* <img src={satellite1} width='250rem'  style={{position : 'absolute' , right : '15rem' , top : '10rem'}} /> */}
          <div className={styles.Stars}>
            {StarsArray.map((el, index) => {
              const size = Math.random() * 5;
              return (
                <div
                  key={index}
                  style={{
                    left: `${Math.random() * 100}vw`,
                    top: `${Math.random() * 100}vh`,
                    animationDelay: `${Math.random() * 10}s`,
                    height: `${size}px`,
                    width: `${size}px`,
                    filter: `blur(${Math.random() * 1}px)`,
                  }}
                  className={styles.Star}
                >
                  {" "}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className={[styles.Section, styles.About].join(" ")}>
        <div className={styles.Content}>
          <div className={styles.AboutVideoContainer}>
            <iframe
              title="Aparat Iframe"
              className={styles.AboutVideo}
              width="100%"
              src="https://www.aparat.com/video/video/embed/videohash/gQoXm/vt/frame"
              allowFullScreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            ></iframe>
          </div>
          <div className={styles.Details}>
            <h1>رویداد زمین صاف چیست؟</h1>
            <p>
              قضیه بر میگرده به چند صد سال قبل! <br />
              زمانی که مردم فکر می‌کردند زمین صافه! اما یکسری افراد اومدن و این
              تفکر رو تغییر دادن.
              <br />
              ما به این افراد می‌گیم تغییر دهندگان زمین صاف!
            </p>
            <p>
              توی رویداد زمین صاف، ما از افرادی که در زمینه‌های مختلف دست به
              تغییر زمین‌های صافشون زدن دعوت می‌کنیم‌، تا برامون از تجربیاتشون
              بگن.
            </p>
          </div>
        </div>
      </section>
      {openModal ? (
        <div
          className={styles.ModalContainer}
          onClick={() => setOpenModal(false)}
        >
          <div color="neutral" variant="plain" className={styles.Modal}>
            <ModalClose />
            <h2>شما یک تغییر دهنده زمین صاف هستید!</h2>
            <Typography>
              اگر علاقه مندید که ببینید شبیه به کدام یک از تغییردهدگان معروف
              زمین صاف هستید در تست زیر شرکت کنید
            </Typography>
            <Link className={styles.ModalCTA} to="/personalities">
              <Button variant="solid">شرکت در تست</Button>
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Home;
