import TeamMemebers from "../../Components/TeamMemebrs/TeamMemebers";
import styles from "./About.module.css";
import BlurCircle from "../../Components/BlurCircle/BlurCircle";
import Head from "../../Components/Head/Head";
import api from "../../api/api";
import { useEffect, useState } from "react";
const About = (props) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    api.get('/members').then((res) => {
      setData(res.data)
      console.log(res.data)
    })
  }, [])
  return (
    <div className={styles.About}>
      <Head title="رویداد زمین صاف - درباره ما " description="درباره ما بیشتر بدانید!" />
      <section className={[styles.Section, styles.Top].join(' ')}>
        <div className={styles.Content}>
          <h1>تیم برگزار کننده</h1>
          <p>این تیمیه که باعث شده فلت ارث شکل بگیره!</p>
         <TeamMemebers title={''} members={data} />


        </div>
      </section>
      <BlurCircle size='240px' top='240px' right='32px' color='white' opacity='0.25' />
      <BlurCircle size='240px' top='120px' left='32px' color='white' opacity='0.25' />
    </div>
  )
}

export default About;