import { Button } from "@mui/joy";
import { useState } from "react";
import styles from "./Sponsor.module.css";
const Sponsor = ({ name, detail, image, description , link }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        
        className={styles.Sponsor}
      >
        <a href={link} target="_blank"><img  width="128" height="128" alt={name} src={image} /></a>
        <div className={styles.Details}>
          <h3 className={styles.Name}>{name}</h3>
          <p className={styles.Detail}>{detail}</p>
          <Button onClick={() => {
          setOpen(!open);
        }} className={styles.AboutBtn} variant="soft">
            درباره حامی
          </Button>
          {open && description ? (
            <div onClick={() => {
                setOpen(!open);
              }} className={styles.Modal}>
              <div className={styles.SponsorDetails}>
              <img width="128" height="128" alt={name} src={image} />
              <div>
              <h3 className={styles.Name}>{name}</h3>
              <p className={styles.Detail}>{detail}</p>
              </div>
              </div>
              <p className={styles.Description}>{description}</p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Sponsor;
