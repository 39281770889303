import styles from "./HamMenu.module.css";
import Navigation from "../Navigation/Navigation";
import LinkButton from "../Buttons/LinkButton/LinkButton";
import Logo from "../Logo/Logo";
import { useState } from "react";
import { Button, IconButton } from "@mui/joy";

const HamMenu = (props) => {
  const [showList, setShowList] = useState(false);
  const HamMenuClickHandler = (e) => {
    setShowList((lastState) => !lastState);
  };
  return (
    <div className={styles.HamMenu} onClick={HamMenuClickHandler}>
      <div
        className={[
          styles.HamMenuButton,
          showList ? styles.CloseButton : null,
        ].join(" ")}
      >
        <div className={styles.FirstLine}></div>
        <div className={styles.SecondLine}></div>
        <div className={styles.ThirdLine}></div>
      </div>
      <div
        className={[styles.HamMenuList, showList ? styles.ShowList : null].join(
          " "
        )}
      >
        <Logo />
        <Navigation Horizontal />
        <a style={{width : '100%'}} rel="noreferrer" target="_blank" href="https://evand.com/events/flatearth-carbon">
          <Button sx={{width : '100%'}} variant="solid">
            ثبت نام در رویداد
          </Button>
        </a>
      </div>
    </div>
  );
};

export default HamMenu;
