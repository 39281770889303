import NavigationItem from "./NavigationItem/NavigationItem";
import styles from './Navigation.module.css';
const Navigation = ({ Horizontal }) => {
  return (
    <nav className={[styles.Navigation, Horizontal ? styles.Horizontal : null].join(' ')}>
      <NavigationItem to="/home">خانه</NavigationItem>
      <NavigationItem to="/events">رویدادها</NavigationItem>
      <NavigationItem to="/about">تیم برگزار کننده</NavigationItem>
      <NavigationItem blank to="https://virgool.io/@flatearthevent" link>بلاگ</NavigationItem>
      <NavigationItem to="/contact">تماس با ما</NavigationItem>
    </nav>
  );
};

export default Navigation;
