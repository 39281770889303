import { Button,   TextField } from "@mui/joy";
import { useState } from "react";
import styles from './Personalities.module.css';
const Personalities = () => {
    const [stage, setStage] = useState('phoneNum');
    return <div  className={styles.Personalities}>
            <header>
            <h1>تغییردهنده زمین صاف</h1>
            <p className={styles.Description}>
            <b> قبل از شروع، </b>
                برای ارسال کردن نتیجه تست باید شماره تلفنتون رو داشته باشیم
            </p>
            </header>
            <TextField label="شماره تلفن"  />
            <div className={styles.NextContainer}>
            <Button className={styles.NextButton} variant="solid">مرحله بعد</Button>
            </div>
        </div>
}
export default Personalities;